/**************************/
/* GLOBAL */
/**************************/

:root {
  --main-color: #339af0;
  --main-text-grey: #555;
  --light-grey: #ddd;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  font-family: 'Inter', sans-serif;
  line-height: 1;
}

/**************************/
/* HEADER SECTION */
/**************************/

.header {
  font-family: 'Montserrat', sans-serif;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-title {
  font-size: 1.4rem;
}

.config-form {
  display: flex;
  gap: 1rem;
}

.config-form {
  font-family: 'Inter', sans-serif;
  
}

.config-form-select {
  font-family: 'Inter', sans-serif;
  font-size: 1.6rem;
  border: none;
  border-radius: 4px;
  padding: 0.5rem;
  color: #333;
  background-color: #fff;
  cursor: pointer;
  outline: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.config-form-select:focus,
.config-form-update-btn:focus,
.reset-btn:focus {
  box-shadow: 0 0 0 2px var(--main-color);
}

.config-form-select option {
  padding: 0.5rem;
}
.btn {
  font-family: 'Inter', sans-serif;
  font-size: 1.6rem;
  font-weight: 600;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  background-color: #add7f9;
  cursor: pointer;
  outline: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
}

.btn:hover {
  transform: scale(1.05);
  background-color: var(--main-color);
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); */
}

/**************************/
/* SCHEDULE SECTION */
/**************************/
.schedule-section {
  margin: 0 auto;
  max-width: 60rem;
}

.daily-schedule-form {
  margin: 0 auto;
  margin-top: 2rem;
  display: grid;

  grid-template-columns: 8rem 50rem;
}

.label-col,
.input-col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.label-col {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  text-align: right;
}

.input-col {
  grid-column: 2 / 3;
}

.schedule-line {
  gap: 5rem;
}

.schedule-label {
  font-size: 1.8rem;
  font-weight: bold;
  color: var(--main-text-grey);
  margin-right: 1rem;
  width: 5rem;
}

.schedule-input {
  border: 1px solid #ccc;
  padding: 0.5rem;
  width: 50rem;
  font-size: 1.8rem;
  height: 1.6rem;
  border: none;
  border-bottom: 1px solid var(--light-grey);
  transition: all 0.2s;
}

.schedule-input.hover:hover {
  transform: scale(1.05);
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.schedule-input:focus {
  transform: scale(1);
  outline: none;
  border-radius: 4px;
  box-shadow: 0 0 0 2px var(--main-color);
}
