:root {
  --main-color: #339af0;
  --main-text-grey: #555;
  --light-grey: #ddd;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  font-family: Inter, sans-serif;
  line-height: 1;
}

.header {
  height: 6rem;
  justify-content: space-between;
  align-items: center;
  font-family: Montserrat, sans-serif;
  display: flex;
}

.header-title {
  font-size: 1.4rem;
}

.config-form {
  gap: 1rem;
  font-family: Inter, sans-serif;
  display: flex;
}

.config-form-select {
  color: #333;
  cursor: pointer;
  background-color: #fff;
  border: none;
  border-radius: 4px;
  outline: none;
  padding: .5rem;
  font-family: Inter, sans-serif;
  font-size: 1.6rem;
  box-shadow: 0 2px 4px #0003;
}

.config-form-select:focus, .config-form-update-btn:focus, .reset-btn:focus {
  box-shadow: 0 0 0 2px var(--main-color);
}

.config-form-select option {
  padding: .5rem;
}

.btn {
  cursor: pointer;
  background-color: #add7f9;
  border: none;
  border-radius: 4px;
  outline: none;
  padding: .5rem 1rem;
  font-family: Inter, sans-serif;
  font-size: 1.6rem;
  font-weight: 600;
  transition: all .2s ease-in-out;
  box-shadow: 0 2px 4px #0003;
}

.btn:hover {
  background-color: var(--main-color);
  transform: scale(1.05);
}

.schedule-section {
  max-width: 60rem;
  margin: 0 auto;
}

.daily-schedule-form {
  grid-template-columns: 8rem 50rem;
  margin: 2rem auto 0;
  display: grid;
}

.label-col, .input-col {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.label-col {
  text-align: right;
  grid-area: 1 / 1 / 2 / 2;
}

.input-col {
  grid-column: 2 / 3;
}

.schedule-line {
  gap: 5rem;
}

.schedule-label {
  color: var(--main-text-grey);
  width: 5rem;
  margin-right: 1rem;
  font-size: 1.8rem;
  font-weight: bold;
}

.schedule-input {
  width: 50rem;
  height: 1.6rem;
  border: none;
  border-bottom: 1px solid var(--light-grey);
  padding: .5rem;
  font-size: 1.8rem;
  transition: all .2s;
}

.schedule-input.hover:hover {
  border-radius: 4px;
  transform: scale(1.05);
  box-shadow: 0 2px 4px #0003;
}

.schedule-input:focus {
  box-shadow: 0 0 0 2px var(--main-color);
  border-radius: 4px;
  outline: none;
  transform: scale(1);
}

/*# sourceMappingURL=index.a5981799.css.map */
